<template>
  <a-result status="error" title="Prohibition of access">
    <template #extra>
            <a-button key="console" type="primary">
              禁止访问
            </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
